import React from 'react';
import IndexPage from './index/IndexPage';
import { saveUTMSessionParams } from "pubweb-smokey/dist/utils/utils";

const AppRoot = (props) => {

  // save any utm params to local session cache
  if(props.location && props.location.search) {
    saveUTMSessionParams(props.location.search);
  }

  return <IndexPage />
};

export default AppRoot;